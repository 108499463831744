import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import imageCompression from 'browser-image-compression';
import FlipCameraIos from '@material-ui/icons/FlipCameraIos';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 20px;
`;

const Box = styled.div`
  width: 100%;
  height: 100%;
  max-width: 500px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
`;

const Header = styled.h1`
  color: #ffffff;
  font-size: 2.5em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px 10px 0 0;
  background-color: #007BFF;
  text-align: center;
  margin: 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background-color: #ffffff;
`;

const TextArea = styled.textarea`
  margin: 15px 0;
  padding: 15px;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
  resize: vertical;
`;
const LoadingIndicator = styled.div`
  display: ${props => props.isLoading ? 'block' : 'none'};
  width: 80px;
  height: 80px;
  margin: 60px auto;
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
const Overlay = styled.div`
  display: ${props => props.isLoading ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
`;
const Button = styled.button`
  margin: 25px 0px;
  padding: 15px;
  width: 100%;
  color: #ffffff;
  background-color: #007BFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #0056b3;
  }
`;

const Video = styled.video`
  height: 100%;
  width: 100%;
  object-fit: cover;
  transform: ${props => props.cameraFacingMode === 'user' ? 'scaleX(-1)' : 'none'};
`;

const CaptureButton = styled.button`
  display: ${props => props.imageData ? 'none' : 'block'};
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -30%);
  height: 80px;
  width: 80px;
  border-radius: 60%;
  background-color: rgba(0, 123, 255, 0.3);
  border: 7px solid #007BFF;
  z-index: 10;
  transition: background-color 0.3s ease; // Hinzufügen einer Übergangsanimation
  
  &:hover {
    background-color: rgba(0, 123, 255, 0); // Ändern der Opazität auf 0 beim Darüberfahren
  }
`;


const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 130%; // Diese Zeile kann geändert werden, um das gewünschte Seitenverhältnis zu erreichen.
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CloseButton = styled.button`
    border: none;
    background: none;
    color: #fff; // Change the color to white
    font-size: 20px;
    cursor: pointer;
    position: absolute; // Position the button absolutely
    top: 10px; // Position it 10px from the top
    left: 10px; // Position it 10px from the left

    &:hover {
        color: #ccc; // This is just an example, feel free to change the color
    }
`;

const VideoContainer = styled.div`
  position: relative;
`;

const FlipCameraIcon = styled(FlipCameraIos)`
  position: absolute;
  top: 10px;
  right: 10px;
  fill: white;
  cursor: pointer;
  &:hover {
    fill: #ccc;
  }
`;


const FileInput = styled.input`
  display: none;
`;

const UploadButton = styled.button`
  type: 'button';
  cursor: pointer;
  position: absolute;
  bottom: 0%;
  left: 80%;
  transform: translate(-50%, -50%); // Hinzugefügt um Button zu zentrieren
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: rgb(137, 177, 220);
  border: 0px solid #007BFF;
  z-index: 10;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(0, 123, 255, 1);
  }

`;
const MessageForm = () => {
    const [cameraFacingMode, setCameraFacingMode] = useState('user');
    const videoRef = useRef();
    const [isCameraOn, setCameraOn] = useState(false);
    const [imageData, setImageData] = useState(null);
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef();

    useEffect(() => {
        if (!localStorage.getItem("firstVisit")) {
            toast.info("Hey Anni, eventuell gibt es einige Verbesserungen. Schau mal, du kannst jetzt auch die Außenkamera verwenden. Außerdem ist das Bild nicht mehr so komisch gespiegelt und wenn du ein Bild machst, ist es nicht mehr gecroppt.");
            localStorage.setItem("firstVisit", "true");
        }
    }, []);


    useEffect(() => {
        const timer = setTimeout(() => {
            if (!isCameraOn) {
                startCamera();
            }
        }, 500); // Verzögerung von 500 Millisekunden

        // Stellen Sie sicher, dass der Timer beim Aufräumen des Effekts gelöscht wird
        return () => clearTimeout(timer);
    }, [isCameraOn]);

    useEffect(() => {
        startCamera();
    }, [cameraFacingMode]);

    const flipCamera = () => {
        setCameraFacingMode(prevFacingMode => prevFacingMode === 'user' ? 'environment' : 'user');
        setCameraOn(false); // Setze isCameraOn auf false, um den vorherigen Stream zu stoppen
    };

    const handleFileInput = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setImageData(reader.result);
            setSelectedFile(file);
            videoRef.current.srcObject.getTracks().forEach(track => track.stop());
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const startCamera = async () => {
        if (!videoRef.current) return;
        try {
            // Stoppen und löschen des vorherigen Streams, wenn es einen gibt
            if (videoRef.current.srcObject) {
                videoRef.current.srcObject.getTracks().forEach(track => track.stop());
                videoRef.current.srcObject = null;
            }
            videoRef.current.srcObject = await navigator.mediaDevices.getUserMedia({
                video: {facingMode: cameraFacingMode}
            });
            setCameraOn(true);
        } catch (error) {
            console.error("Fehler beim Abrufen des Kamera-Streams:", error);
        }
    };


    const captureImage = (e) => {
        e.preventDefault();
        const canvas = document.createElement('canvas');
        const videoElement = videoRef.current;

        // Setze die Größe des Canvas auf die tatsächliche Größe des Video-Elements im DOM
        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;

        const ctx = canvas.getContext('2d');

        // Spiegele das Bild nur, wenn die Frontkamera aktiv ist
        if (cameraFacingMode === 'user') {
            ctx.translate(videoElement.videoWidth, 0);
            ctx.scale(-1, 1);
        }

        // Zeichne das Video auf dem Canvas mit der gleichen Größe
        ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
        setImageData(canvas.toDataURL('image/jpeg'));

        videoRef.current.srcObject.getTracks().forEach(track => track.stop());
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Überprüfen Sie, ob weder Bild noch Nachricht vorhanden sind
        if (!imageData && !message.trim()) {
            toast.error('Bitte nehmen Sie ein Bild auf oder geben Sie eine Nachricht ein bevor Sie die Nachricht senden.');
            return;
        }

        setIsLoading(true);

        // Wenn kein Bild vorhanden ist, senden Sie einfach die Nachricht
        if (!imageData) {
            sendMessage(null);
            return;
        }



        const options = {
            maxSizeMB: 2.5,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            fileType: 'jpeg',
            quality: 0.5,
        };

        try {
            let imageBlob;
            if (selectedFile) {
                imageBlob = selectedFile;
            } else {
                imageBlob = await fetch(imageData).then(r => r.blob());
            }
            const compressedFile = await imageCompression(imageBlob, options);
            const reader = new FileReader();
            reader.readAsDataURL(compressedFile);
            reader.onloadend = async function () {
                const base64data = reader.result;
                const base64Image = base64data.split(',')[1];
                sendMessage(base64Image);
            };
        } catch (error) {
            console.log(error);
        }
    };

    const renderVideoOrImage = () => {
        if (imageData) {
            return (
                <ImageContainer>
                    <Image src={imageData} />
                    <CloseButton onClick={closeImage}>X</CloseButton>
                </ImageContainer>
            );
        } else {
            if (videoRef.current) {
                return (
                    <VideoContainer>
                        <Video ref={videoRef} autoPlay playsInline cameraFacingMode={cameraFacingMode} />
                        <CaptureButton imageData={imageData} onClick={captureImage} />
                        <FileInput type="file" accept="image/*" ref={fileInputRef} onChange={handleFileInput} />
                        <UploadButton onClick={handleUploadButtonClick}>
                            <CloudUploadIcon style={{ color: "white" }} />
                        </UploadButton>
                        <FlipCameraIcon onClick={flipCamera} />
                    </VideoContainer>
                );
            } else {
                const videoElement =
                    <VideoContainer>
                        <Video ref={videoRef} autoPlay playsInline cameraFacingMode={cameraFacingMode} />
                        <CaptureButton imageData={imageData} onClick={captureImage} />
                        <FileInput type="file" accept="image/*" ref={fileInputRef} onChange={handleFileInput} />
                        <UploadButton onClick={handleUploadButtonClick}>
                            <CloudUploadIcon style={{ color: "white" }} />
                        </UploadButton>
                        <FlipCameraIcon onClick={flipCamera} />
                    </VideoContainer>;
                startCamera();
                return videoElement;
            }
        }
    };



    const sendMessage = async (base64Image) => {
        const params = new URLSearchParams();
        params.append('token', 'a8sokqcwqp3qgvv9cbr5xgjbwozgya');
        params.append('user', 'u78qndkh3jdx67wh4ru3xmvrcmm5a7');
        params.append('device', 'mori');
        params.append('title', 'Anni');
        params.append('message', message || 'Bild:');
        if (base64Image) {
            params.append('attachment_base64', base64Image);
            params.append('attachment_type', 'image/jpeg');
        }

        const response = await fetch('https://api.pushover.net/1/messages.json', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: params.toString(),
        });

        if (response.ok) {
            toast.success('Nachricht erfolgreich gesendet!');
            setMessage('');
            setImageData(null);
            setCameraOn(false);
        } else {
            toast.error('Es gab einen Fehler beim Senden der Nachricht.');
        }
        setIsLoading(false);
    };
    const handleUploadButtonClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        toast.error("Nein Anni diese Funktion gibts für dich noch nicht!!!");

/*
        fileInputRef.current.click();
*/
    };

    const closeImage = async () => {
        setImageData(null);

        setCameraOn(false);
    };

    return (
        <Container>
            <Box>
                <Header>Send to MoriMori</Header>
                <Form onSubmit={handleSubmit}>
                    {renderVideoOrImage()}
                    <TextArea value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Nachricht" />
                    <Button type="submit">Senden</Button>
                </Form>
            </Box>
            <Overlay isLoading={isLoading}>
                <LoadingIndicator isLoading={isLoading}/>
            </Overlay>
            <ToastContainer />


        </Container>
    );
};
export default MessageForm;