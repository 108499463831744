import React from 'react';
import './App.css';
import MessageForm from './components/MessageForm'; // Stellen Sie sicher, dass der Pfad korrekt ist

function App() {
  return (
      <div className="App">
        <MessageForm /> {/* Hier wird die MessageForm Komponente eingebunden */}
      </div>
  );
}

export default App;
